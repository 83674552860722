<template>
    <div id="collection-sidebar">
        sidebar
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped>

</style>
