<template>
    <div id="collection-container">
        <div class="breadcumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.params.title}}</el-breadcrumb-item>
            
            </el-breadcrumb>
        </div>
        <div class="collection-wrapper">
            <!-- <Sidebar /> -->
            <Content :collectionList = "collectionList"/>
        </div>
        

    </div>
</template>

<script>
import {getProductList} from '@/api/mall/index.js'
import Sidebar from '@/views/collection/sidebar/Sidebar.vue'
import Content from '@/views/collection/collection-content/Collection-content.vue'
export default {
    components:{
        Sidebar,
        Content
    },
    mounted() {
        if(!this.collectionList.list){
            this.getProductList(this.$route.params.id)
        }
    },
    watch:{
        $route(to,from) {
            this.getProductList(to.params.id)
        }
    },
    data() {
        return {
            collectionList:{}
        }
    },
    methods: {
        getProductList(id){
            getProductList(JSON.stringify({collectId:id})).then(res=>{
                this.collectionList = res.data.list
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
#collection-container 
    padding 0 13.75%
    background #fff
    border-top 1px solid #a0a0a0
    .collection-wrapper
        display flex
    #collection-sidebar
        width 16.5%
        color #000
    .sort-bar
        display flex
        padding 0 .625rem
        margin-bottom 1.25rem
        justify-content space-between
        align-items center
        .collection-count
            font-size .875rem
            color #333
    .el-breadcrumb
        height 5rem
        line-height 5rem
        padding 0 .625rem



</style>
