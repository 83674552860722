<template>
    <div id="collection-content">
        <div class="filter-bar">
            <div class="sort-bar">
                <el-select v-model="selectVal" placeholder="please select sort methods">
                    <el-option
                    v-for="item in sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
                <div class="collection-count">{{collectionList.totalSize}} item(s)</div>
            </div>
        </div>
        <div class="collection-list" v-if="dataList.length > 0">
            <div class="collection-list-item" v-for="(item,index) in dataList" :key="item.title + index">
                <router-link class="img-box" :to="{name:'detail',params:{title:item.handle,id:item.id}}" >
                    <img :src="item.img.src" alt="">                
                </router-link>
                <div class="product-title">{{item.title}}</div>
                <div class="price">${{item.price}}</div>
            </div>
            
            
        </div>
    </div>
</template>

<script>
export default {
    props:['collectionList'],
    props:{
        collectionList:{
            type:Object,
            default:{resultList:[]}
        }
    },
    computed:{
        dataList(){
            if(this.collectionList.resultList){
                return this.collectionList.resultList.map((item)=>{
                    item.img = JSON.parse(item.image)
                    return item
                }) 
            }else{
                return []
            }             
                           
        }                            
    },
    data() {
        return {
            selectVal:'1',
            sortOptions:[
                {
                    label:'sort by xxx',
                    value:'1'
                },
                {
                    label:'sort by xxx2',
                    value:'2'
                },
                {
                    label:'sort by xxx3',
                    value:'3'
                },
                {
                    label:'sort by xxx4',
                    value:'4'
                },
            ]
        }
    },
}
</script>

<style lang="stylus" scoped>
div#collection-content
    flex 1
.collection-list 
    display flex
    flex-wrap wrap
    .collection-list-item
        box-sizing border-box
        width 25%
        padding 0 .625rem            
        margin-bottom 3.125rem
        img 
            width 100%
    .title 
        font-size 1.875rem
        color #000
        text-align center
        margin-bottom 2.25rem
    .product-title 
        font-size .875rem
        color #222
        margin-top 1.25rem
    .price 
        font-size: 1rem
        color: #222
        margin-top: .75rem
.sort-bar
    display flex
    padding 0 .625rem
    margin-bottom 1.25rem
    justify-content space-between
    align-items center
    .collection-count
        font-size .875rem
        color #333
.el-breadcrumb
    height 5rem
    line-height 5rem
    padding 0 .625rem
a.img-box {
    display: block;
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
}
.img-box img {
    position: absolute;
    left: 0;
    top: 0;
}
</style>


